let CognitoRegion = 'ap-northeast-1';
// let CognitoUserPool = 'ap-northeast-1_Lx4RhvYkv'; //dev用
// let CognitoUserPool = 'ap-northeast-1_tzQ9aOidb'; //stg環境向け
let CognitoUserPool = 'ap-northeast-1_7zV2t6Emz'; //本番環境向け

// let CognitoUserPoolClient = '6d3ho1qrpe92cdk3625n9gbe2j'; //dev用
// let CognitoUserPoolClient = '2r7kba5a4te7jn40hab50dpjd8'; //stg環境向け
let CognitoUserPoolClient = '7svr3m0ddn2onka2hqdmv8v0a2'; //本番環境向け

// let CognitoDomainPrefix = 'service-dev-user';  //dev用
// let CognitoDomainPrefix = 'service-user-test'; //stg環境向け
let CognitoDomainPrefix = 'blender-melco'; //本番環境向け

let amplifyConfig = {
    Auth: {
        region: CognitoRegion,
        userPoolId: CognitoUserPool,
        userPoolWebClientId: CognitoUserPoolClient,
        oauth: {
            domain: `${CognitoDomainPrefix}.auth.${CognitoRegion}.amazoncognito.com`,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            // redirectSignIn: 'http://localhost:3000',
            // redirectSignOut: 'http://localhost:3000/logOut',
            // redirectSignIn: 'https://settingmfa.service.sandbox.blender-melco.com', //dev用
            // redirectSignOut: 'https://settingmfa.service.sandbox.blender-melco.com/logOut', //dev用
            // redirectSignIn: 'https://settingmfa.platform.stg.blender-melco.com', //stg環境ドメイン向け
            // redirectSignOut: 'https://settingmfa.platform.stg.blender-melco.com/logOut', //stg環境ドメイン向け
            redirectSignIn: 'https://settingmfa.platform.service.blender-melco.com', //本番環境ドメイン向け
            redirectSignOut: 'https://settingmfa.platform.service.blender-melco.com/logOut', //本番環境ドメイン向け
            responseType: ['token']
        },
        "federationTarget": "COGNITO_USER_POOLS"
    },
    API: {  
        endpoints: [
          {
            name: 'api_url_base',
            // endpoint: 'https://bahsdsoqjh.execute-api.ap-northeast-1.amazonaws.com/dev',
            // endpoint: ' https://vurooo03r7.execute-api.ap-northeast-1.amazonaws.com/stg', //stg環境ドメイン向け
            endpoint: 'https://4a20oiadjk.execute-api.ap-northeast-1.amazonaws.com/prod', //本番環境ドメイン向け
            custom_header: async () => { 
              return { Authorization : 'token' } 
           }
          }
        ]
      }
};
export default amplifyConfig;